import { PixelRatio } from 'react-native'

function getDecimal(num) {
  const n = Math.abs(num)
  const dec = n - Math.floor(n)
  const decStr = (Math.round(dec * 100) / 100).toString()
  if (decStr.split('.').length) {
    return decStr.split('.')[1]
  } else return ''
}

export function formatNumReais(num) {
  const integerStr = num.toString().split('.')[0]
  const decimalStr = getDecimal(num)
  const integerOut = integerStr.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  let decimalOut = decimalStr
  if (!decimalStr || !decimalStr.length) {
    decimalOut = '00'
  } else if (decimalStr.length === 1) {
    decimalOut = decimalStr + '0'
  } else if (decimalStr.length > 2) {
    decimalOut = decimalStr.substr(0, 2)
  }
  return 'R$ ' + integerOut + ',' + decimalOut
}

export function pxTodp(px) {
  return PixelRatio.getPixelSizeForLayoutSize(px)
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
