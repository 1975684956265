import axios from 'axios'
import { Config } from '../Config'

const scheduleApi = axios.create({
  baseURL: Config.SCHEDULE_API_URL,
})

function freeTime() {
  return scheduleApi.get('/calendar/free')
}

function schedule(email, text, date) {
  let body = {
    meet: {
      startDate: date,
      attendee: email,
      text,
    },
  }

  return scheduleApi.post('/calendar', body)
}

export default {
  freeTime,
  schedule,
}
