import React, { useState } from "react";

import {
  View,
  Image,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";

import LogoImage from "../../Assets/Images/logo.png";
import LiaImage from "../../Assets/Images/lia.png";

import { Colors } from "../../Theme";

const Welcome = ({ navigation }) => {
  const [loading, setLoading] = useState(false);

  async function getSessionId() {
    setLoading(true);
    navigation.navigate("ChatScreen");
    setLoading(false);
  }

  return (
    <View style={styles.container}>
      <View style={styles.yellowContainer}>
        <Image style={styles.logo} source={LogoImage} resizeMode="contain" />
      </View>

      <View style={styles.blueContainer}>
        <Image style={styles.lia} source={LiaImage} resizeMode="contain" />
        <Text style={styles.HiText}>Olá</Text>
        <Text style={styles.infoText}>
          Eu sou a Lia, estou aqui para te ajudar a negociar seus débitos com o
          Município do Recife.
        </Text>
      </View>
      <View style={styles.whiteContainer}>
        <View style={styles.whiteBackground}>
          <TouchableOpacity
            style={styles.containerButton}
            onPress={getSessionId}
          >
            {loading ? (
              <ActivityIndicator />
            ) : (
              <Text style={styles.textButton}>Vamos lá!</Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.yellow,
  },
  logo: {
    height: 150,
    width: 100,
    marginTop: 90,
  },
  yellowContainer: {
    flex: 3,
    alignItems: "center",
    backgroundColor: Colors.yellow,
  },
  lia: {
    height: 160,
    width: 100,
  },
  blueContainer: {
    flex: 3,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.lightBlue,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },

  HiText: {
    textAlign: "center",
    color: Colors.darkBlue,
    fontSize: 30,
  },
  infoText: {
    textAlign: "center",
    color: "white",
    fontSize: 16,
    marginHorizontal: 66,
  },
  whiteContainer: {
    backgroundColor: Colors.lightBlue,
  },

  whiteBackground: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: Colors.white,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 20,
    
  },

  containerButton: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 50,
    backgroundColor: Colors.yellow,
  },
  textButton: {
    color: "white",
    fontSize: 22,
  },
});



export default Welcome;
