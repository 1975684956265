import React, { useState, useCallback, useEffect } from 'react'

import {
  TextInput,
  View,
  TouchableOpacity,
  Text,
  processColor,
  ActivityIndicator,
  TouchableHighlight,
  Modal,
  Image,
    StyleSheet,
} from 'react-native'
import { Colors } from '../../Theme'
import { Ionicons } from '@expo/vector-icons';

import LiaAvatar from '../../Assets/Images/avatar_lia.png'



const Style = StyleSheet.create({
    wrapperStyle: {
        flexDirection: "row",
        alignItems: 'center',
        backgroundColor: Colors.yellow,
        height: 60,
    },

    profileImageStyle: {
        height: 50,
        width: 50,
        borderRadius: 25,
    },

    profileNameSyle: {
        fontSize: 17,
        color: Colors.darkBlue,
        fontWeight: 'bold',
        marginLeft: 15,
        
    }
})



const ChatHeader = ({ onPressBackButton = () => {}}) => {
    return (
      <View style={Style.wrapperStyle}> 
        <TouchableOpacity onPress={onPressBackButton} ><Ionicons name="ios-arrow-dropleft" size={40} color={Colors.darkBlue} /></TouchableOpacity>
        <Image style={Style.profileImageStyle} source={LiaAvatar}/>
        <Text style={Style.profileNameSyle}>Lia - Prefeitura do Recife</Text>
      </View>
    )
  }


export default ChatHeader;
