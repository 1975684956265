import moment from 'moment'
import debtFromSequencial from './mocks/debtFromSequencial'
import debtByMonth from './mocks/debtByMonth';

const TIME = 0

function sendMock(data) {
  return new Promise((resolve) => {
    setTimeout(() => resolve({ data }), TIME)
  })
}

function getDebtBySequencial(sequencial) {
  return sendMock(debtFromSequencial)
}

function getPDFsByMonthCurrentYear(sequencial, monthStart, monthEnd) {
  monthEnd = monthEnd || monthStart

  let body = {
    inscricaoOuSequencial: sequencial,
    tipoProcesso: 'IPTU',
    parcelaInicial: monthStart,
    parcelaFinal: monthEnd,
  }

  return sendMock(debtByMonth)
}

function getPDFInCashCurrentYear(sequencial) {
  let body = {
    sequencial: sequencial,
  }

  return apiClient.post('/emitirDAM/emitirDamUnicoIptu', body)
}

function simulateInstallments(cda, qtyInstallments) {
  let body = {
    numParcelas: qtyInstallments,
    dataVencimento: moment()
      .add(5, 'days')
      .format('dd/MM/YYYY'),
    serie: 1,
    cda: cda,
    tipoDebito: 'CDA',
  }

  return apiClient.post('/parcelamento/simular', body)
}

function confirmInstallments(cda, qtyInstallments, email) {
  let body = {
    numParcelas: qtyInstallments,
    dataVencimento: moment()
      .add(5, 'days')
      .format('dd/MM/YYYY'),
    serie: 1,
    cda: cda,
    tipoDebito: 'CDA',
    email,
  }

  return apiClient.post('/parcelamento/parcelar', body)
}


function getPDFInCash(sequencial, selectedDebt, type) {
  return sendMock(debtByMonth);
}


export const debtService =  {
  getDebtBySequencial,
  getPDFsByMonthCurrentYear,
  getPDFInCashCurrentYear,
  simulateInstallments,
  getPDFInCash,
  confirmInstallments,
}




