export const USER = {
  CLIENT: 1,
  SYSTEM: 2,
}

export const PLACEHOLDER = {
  DEFAULT: 'Digite aqui...',
  ASK_NAME: 'Digite aqui seu nome',
  ASK_SEQUENCIAL: 'Digite aqui o sequêncial no imóvel',
}
