import axios from "axios";
import { Config } from "../Config";
import moment from "moment";

const apiClient = axios.create({
  baseURL: "https://lia.recife.pe.gov.br/api",
  headers: {
    Authorization:
      "Basic RzVxM08tVUFYVFVrT2FhUzVFZzVkYjp3aG5NSldGdkFjU2VuLUpNelo0T204",
    "Content-Type": "application/json",
  },
});

function getDebtBySequencial(sequencial) {
  return apiClient.post("/debitosSequencial", { sequencial });
}

function getPDFsByMonthCurrentYear(sequencial, monthStart, monthEnd) {
  monthEnd = monthEnd || monthStart;

  let body = {
    inscricaoOuSequencial: sequencial,
    tipoProcesso: "IPTU",
    parcelaInicial: monthStart,
    parcelaFinal: monthEnd,
  };

  return apiClient.post("/emitirDAM/emitirListaDamParcelaAtual", body);
}

function getPDFInCash(sequencial, selectedDebt, type) {
  let route = "emitirDamUnicoIptu";
  let body = {
    sequencial,
  };

  if (type == "cda") {
    route = "emitirDamCda";
    body = {
      dataVencimento: moment().add(5, "days").format("YYYY-MM-DD"),
      serieCda: 1,
      numeroCda: selectedDebt.cda,
    };
  } else if (selectedDebt.exercicio != new Date().getFullYear()) {
    route = "emitirDamUnicoIptuAnterior";
    body = {
      sequencial,
      dataVencimento: moment().add(5, "days").format("YYYY-MM-DD"),
      ano: selectedDebt.exercicio,
    };
  }

  console.log(body);

  return apiClient.post(`/emitirDAM/${route}`, body);
}

function simulateInstallments(selectedDebt, type, qtyInstallments) {
  let body = {
    numParcelas: qtyInstallments,
    dataVencimento: moment().add(5, "days").format("dd/MM/YYYY"),
    serie: 1,
    cda: selectedDebt.cda,
    tipoDebito: "CDA",
  };

  return apiClient.post("/parcelamento/simular", body);
}

function confirmInstallments(selectedDebt, qtyInstallments, email) {
  let body = {
    numParcelas: qtyInstallments,
    dataVencimento: moment().add(5, "days").format("dd/MM/YYYY"),
    serie: 1,
    cda: selectedDebt.cda,
    tipoDebito: "CDA",
    email,
  };

  return apiClient.post("/parcelamento/parcelar", body);
}

export const debtService = {
  getDebtBySequencial,
  getPDFsByMonthCurrentYear,
  getPDFInCash,
  simulateInstallments,
  confirmInstallments,
};
