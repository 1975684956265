import React, { Component } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import WelcomeScreen from "./Containers/Welcome/Welcome";
import ChatScreen from "./Containers/ChatScreen/ChatScreen";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

const Stack = createStackNavigator();

export default class App extends Component {
  render() {
    return (
      <NavigationContainer>
        <Stack.Navigator headerMode="none" initialRouteName="WelcomeScreen">
          <Stack.Screen
            options={{ title: "Resolve em Todo Lugar" }}
            name="WelcomeScreen"
            component={WelcomeScreen}
          />
          <Stack.Screen
            options={{ title: "Resolve em Todo Lugar - Lia" }}
            name="ChatScreen"
            component={ChatScreen}
          />
        </Stack.Navigator>
      </NavigationContainer>
    );
  }
}
