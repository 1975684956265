import { formatNumReais } from "../Util/util";

import scheduleService from "../Services/scheduleService";
import { PLACEHOLDER, USER } from "../Util/constants";
import moment from "moment";
import { Config } from "../Config";

const debtService = Config.DEBT_SERVICE;

export async function welcome(chat, context = {}) {
  await chat.sendMessage(
    "Olá! Eu sou a Lia e estou aqui para ajudar a negociar seus débitos com o Município do Recife."
  );

  await chat.sendMessage("Por favor, digite seu nome para continuar.");

  chat.setPlaceholder(PLACEHOLDER.ASK_NAME);
  let answer = await chat.waitInput();

  while (answer.text.length <= 3) {
    chat.sendMessage(
      "Seu nome está muito curto. Por favor, escreva seu nome completo."
    );
    answer = await chat.waitInput();
  }

  context.name = answer.text;

  chat.sendMessage(`Muito Obrigado, ${context.name}!`);

  return askSequencial;
}

export async function askSequencial(chat, context = {}) {
  await chat.sendMessage(`Agora precisamos saber o sequencial do seu imóvel.`);
  await chat.sendMessage(
    "O sequencial pode ser encontrado no carnê do IPTU ou em outros documentos emitidos pela Secretaria de Finanças, relativos ao imóvel."
  );
  await chat.sendMessage(`Me infome o número do sequencial de seu imóvel.`);
  chat.setPlaceholder(PLACEHOLDER.ASK_SEQUENCIAL);

  let answer = await chat.waitInput();

  while (answer.text.length < 7 || isNaN(parseInt(answer.text))) {
    if (isNaN(parseInt(answer.text))) {
      chat.sendMessage(
        "Desculpe, mas preciso do sequencial do seu imóvel para continuar."
      );
    } else {
      chat.sendMessage(
        "Este sequencial é muito curto. Ele deve ter 7 ou mais números."
      );
    }

    answer = await chat.waitInput();
  }

  chat.sendMessage(
    `Obrigada. Estou buscando as informações sobre o imóvel. Por favor, aguarde um momento.`
  );
  context.sequencial = answer.text;

  chat.setPlaceholder("Aguarde. Buscando...");
  chat.setLoading(true);

  try {
    let response = await debtService.getDebtBySequencial(context.sequencial);
    let debts = response.data;
    context.debts = debts;

    let qtyAdm = debts.debitosAdministrativos.length;
    let qtyCda = debts.certidaoDividaAtiva.length;

    if (qtyCda >= 1) {
      return handleCda;
    }

    if (qtyAdm >= 1) {
      return handleAdm;
    }

    await chat.sendMessage(
      "Não encontramos dívidas para este sequencial, que bom!"
    );
    return sessionEnd;
  } catch (error) {
    console.log(error);
    chat.sendMessage(
      `Houve um problema por parte nossa. Infelizmente não foi possível consultar as dívidas desde sequencial.`
    );
    chat.sendMessage(`Teria como tentar novamente mais tarde?`);
  } finally {
    chat.setLoading(false);
    chat.setPlaceholder(PLACEHOLDER.DEFAULT);
  }

  return sessionEnd;
}

export async function handleAdm(chat, context) {
  let debts = context.debts;
  let qtyAdm = debts.debitosAdministrativos.length;

  let options = debts.debitosAdministrativos.map((debt) => {
    return { value: debt.exercicio, text: debt.exercicio };
  });

  await chat.sendMessage(
    `Encontrei ${qtyAdm} dívida(s) relacionada(s) a este imóvel. Por favor, selecione o ano que você deseja pagar.`
  );
  chat.showModalAnswers("Selecione uma dívida", options);

  let answer = await chat.waitInput();

  context.yearSelected = answer.text;
  let today = new Date();
  let todayYear = today.getFullYear();

  context.selectedDebt = context.debts.debitosAdministrativos.find(
    (d) => d.exercicio == context.yearSelected
  );

  context.type = "adm";
  if (context.yearSelected == todayYear) {
    return handleCurrentYear;
  } else {
    return handleOldYear;
  }
}

export async function handleCda(chat, context) {
  let debts = context.debts;

  let qtyCda = debts.certidaoDividaAtiva.length;

  let options = debts.certidaoDividaAtiva.map((debt) => {
    return { value: debt.cda, text: debt.exercicios.join(", ") };
  });

  let totalSemDesconto = 0;
  let totalAVista = 0;

  debts.certidaoDividaAtiva.map((debt) => {
    totalSemDesconto += debt.totalSemDesconto.total;
    totalAVista += debt.totalAVista.total;
  });

  await chat.sendMessage(
    `Encontrei ${qtyCda} débito(s) relacionado(s) a este imóvel. Se o pagamento for à vista, há um desconto de 40% nas multas e juros. O valor total do(s) seu(s) débito(s) é ${formatNumReais(
      totalSemDesconto
    )}. Se pagar à vista, o valor cai para ${formatNumReais(totalAVista)}.`
  );

  await chat.sendMessage(
    `Recomendo o pagamento ou parcelamento para evitar a penhora do imóvel, restrições em cadastros de inadimplentes e constrição de bens e ativos financeiros em nome do titular.`
  );

  chat.showModalAnswers("Selecionar uma dívida", options);

  let answer = await chat.waitInput();
  let cdaSelected = answer.text;
  context.type = "cda";
  context.selectedDebt = context.debts.certidaoDividaAtiva.find(
    (d) => d.cda == cdaSelected
  );
  return handleOldYear;
}

export async function handleCurrentYear(chat, context) {
  await chat.sendMessage(
    "Tudo bem. Deixa eu explicar a situação deste ano para você."
  );

  let parcelasVencidas = [];
  let parcelasFuturas = [];

  if (
    context.selectedDebt.parcelasVencidas &&
    context.selectedDebt.parcelasVencidas.parcelas
  ) {
    parcelasVencidas = context.selectedDebt.parcelasVencidas.parcelas;
  }

  if (
    context.selectedDebt.parcelasFuturas &&
    context.selectedDebt.parcelasFuturas.parcelas
  ) {
    parcelasFuturas = context.selectedDebt.parcelasFuturas.parcelas;
  }

  if (parcelasVencidas.length >= 1) {
    await chat.sendMessage(
      `Você tem ${parcelasVencidas.length} parcela(s) vencida(s).`
    );
    await chat.sendMessage(
      `O total dessas parcelas é de: ${formatNumReais(
        context.selectedDebt.parcelasVencidas.totalSemDesconto.total
      )}`
    );
    // await chat.sendMessage(`Nesse valor está incluido:`);
    // await chat.sendMessage(
    //   `Multa de ${formatNumReais(
    //     context.selectedDebt.parcelasVencidas.totalSemDesconto.multa
    //   )} `
    // );
    // await chat.sendMessage(
    //   `Juros de ${formatNumReais(
    //     context.selectedDebt.parcelasVencidas.totalSemDesconto.juros
    //   )} `
    // );

    if (parcelasFuturas.length >= 1) {
      await chat.sendMessage(
        `Além disso, você tem ${parcelasFuturas.length} parcela(s) futura(s)`
      );
      await chat.sendMessage(
        `Totalizando sua dívida em  ${formatNumReais(
          context.selectedDebt.parcelasVencidas.totalSemDesconto.total +
            context.selectedDebt.parcelasFuturas.totalSemDesconto
        )} `
      );
    }

    await chat.sendMessage(
      "Você pode escolher pagar à vista e obter um desconto."
    );
    await chat.sendMessage(
      `O valor total, à vista, ficaria de: ${formatNumReais(
        context.selectedDebt.totalAVista.total
      )}`
    );
    await chat.sendMessage(
      "Ou pode escolher algumas parcelas para pagar sem desconto."
    );
    await chat.sendMessage(
      "Se quiser, podemos agendar um atendimento online para você tirar dúvida sobre este débito."
    );

    chat.showModalAnswers("Escolha a melhor opção para você", [
      { text: "Pagar à Vista", value: "incash" },
      { text: "Escolher apenas uma parcela", value: "installments" },
      { text: "Agendar Atendimento online", value: "schedule" },
    ]);

    let answer = await chat.waitInput();
    if (answer.text == "incash") {
      return handlePaymentInCash;
    } else if (answer.text == "installments") {
      return handlePaymentInstallments;
    } else {
      return scheduleMeeting;
    }
  } else if (parcelasFuturas.length >= 1) {
    await chat.sendMessage(
      `Você tem ${parcelasFuturas.length} parcelas restantes.`
    );
    await chat.sendMessage(`Deseja imprimir a próxima parcela?`);
    chat.showModalAnswers("Confirmar", [{ value: "Sim" }, { value: "Não" }]);
    let answer = await chat.waitInput();

    if (answer.text == "Sim") {
      return getDebtByMonth;
    }
    return sessionEnd;
  } else {
    await chat.sendMessage(
      `As dívidas deste ano estão quitadas. Obrigado por colaborar com a cidade.`
    );

    return sessionEnd;
  }
}

export async function handlePaymentInstallments(chat, context) {
  let parcelasVencidas = context.selectedDebt.parcelasVencidas.parcelas || [];
  let parcelasFuturas = context.selectedDebt.parcelasFuturas.parcelas || [];
  let allInstallments = [...parcelasVencidas, ...parcelasFuturas];
  let options = allInstallments.map((installment) => ({
    text: installment,
    value: installment,
  }));

  chat.showModalAnswers(`Selecionar o mês`, options);

  let answer = await chat.waitInput();
  context.selectedMonth = answer.text;
  return getDebtByMonth;
}

export async function handlePaymentInCash(chat, context) {
  await chat.sendMessage(
    `Confirma o acordo? Será emitido um boleto no valor de ${formatNumReais(
      context.selectedDebt.totalAVista.total
    )}`
  );
  chat.showModalAnswers("Confirmar", [{ value: "Sim" }, { value: "Não" }]);
  let answer = await chat.waitInput();

  if (answer.text == "Não") {
    return sessionEnd;
  }

  await chat.sendMessage("Um momento. Vou buscar pra você.");
  chat.setLoading(true);
  chat.setPlaceholder("Buscando boleto...");

  try {
    let response = await debtService.getPDFInCash(
      context.sequencial,
      context.selectedDebt,
      context.type
    );

    let invoicePdf = response.data.arrayBytePdf[0];
    if (context.type == "adm") {
      var invoiceInfo = response.data.listaDamDto[0];
    } else {
      var invoiceInfo = response.data.damDto[0];
    }

    await chat.sendMessage(invoicePdf, USER.SYSTEM, false, { type: "pdf" });
    await chat.sendMessage(
      `Valor: ${invoiceInfo.valor[0]} - Data Vencimento: ${invoiceInfo.dataVencimento[0]}`
    );
    await chat.sendMessage("O Código de barras é:");
    await chat.sendMessage(invoiceInfo.codigoBarras[0]);
  } catch (error) {
    chat.sendMessage(
      "Desculpe. Tivemos um problema interno. Por favor, venha mais tarde."
    );
    console.log(error);
  }

  chat.setLoading(false);
  chat.setPlaceholder(PLACEHOLDER.DEFAULT);
  return sessionEnd;
}

export async function getDebtByMonth(chat, context) {
  await chat.sendMessage("Um momento. Vou buscar pra você.");
  chat.setLoading(true);
  chat.setPlaceholder("Buscando parcela...");

  let nextDebt =
    context.selectedMonth || context.selectedDebt.parcelasFuturas.parcelas[0];
  try {
    let response = await debtService.getPDFsByMonthCurrentYear(
      context.sequencial,
      nextDebt
    );
    let invoicePdf = response.data.arrayBytePdf[0];
    let invoiceInfo = response.data.listaDamDto[0];

    await chat.sendMessage(invoicePdf, USER.SYSTEM, false, { type: "pdf" });
    await chat.sendMessage(
      `Valor: ${invoiceInfo.valor[0]} - Data Vencimento: ${invoiceInfo.dataVencimento[0]}`
    );
    await chat.sendMessage("O Código de barras é:");
    await chat.sendMessage(invoiceInfo.codigoBarras[0]);
  } catch (error) {
    console.log(error);
    chat.sendMessage(
      "Não foi possível imprimir a parcela. Tente nomente mais tarde."
    );
  } finally {
    chat.setLoading(false);
    chat.setPlaceholder(PLACEHOLDER.DEFAULT);
  }

  return sessionEnd;
}

export async function handleOldYear(chat, context = {}) {
  await chat.sendMessage(
    "Tudo bem. Vou explicar a situação deste débito para você."
  );

  if (context.type == "adm") {
    var totalSemDesconto =
      context.selectedDebt.parcelasVencidas.totalSemDesconto;
    var exercicios = context.selectedDebt.exercicio;
  } else {
    var totalSemDesconto = context.selectedDebt.totalSemDesconto;
    var exercicios = context.selectedDebt.exercicios.join("/");
  }

  await chat.sendMessage(
    `A dívida do(s) exercício(s) ${exercicios} é de ${formatNumReais(
      totalSemDesconto.total
    )}.`
  );

  // await chat.sendMessage(`Nesse valor está incluido:`);
  // await chat.sendMessage(`Multa de ${formatNumReais(totalSemDesconto.multa)} `);
  // await chat.sendMessage(`Juros de ${formatNumReais(totalSemDesconto.juros)} `);
  // if (context.text == "cda") {
  //   await chat.sendMessage(
  //     `Honorários de ${formatNumReais(totalSemDesconto.honorarios)} `
  //   );
  // }

  // let custas = totalSemDesconto.custas;
  // if (custas && custas > 0) {
  //   await chat.sendMessage(
  //     `Custas de ${formatNumReais(totalSemDesconto.custas)} `
  //   );
  // }

  await chat.sendMessage(
    `Você pode pagar à vista, com o desconto de 40% nas multas e juros, e honorários de 15% sobre o valor total. À vista, o valor fica ${formatNumReais(
      context.selectedDebt.totalAVista.total
    )}`
  );

  let options = [
    { text: "Pagar à Vista", value: "incash" },
    { text: "Agendar Atendimento Online", value: "schedule" },
  ];

  if (context.type == "cda") {
    options = [
      { text: "Pagar à Vista", value: "incash" },
      { text: "Simular Parcelamento", value: "installments" },
      { text: "Agendar Atendimento Online", value: "schedule" },
    ];
    await chat.sendMessage(
      "Também pode escolher fazer um parcelamento, com desconto de no máximo 30% nos juros e multa, a depender do número de parcelas."
    );
  }

  // await chat.sendMessage(
  //   "Ou pode agendar atendimento online com um de nossos colaboradorores."
  // );
  chat.showModalAnswers("Escolher opção", options);

  let answer = await chat.waitInput();
  if (answer.text == "incash") {
    return handlePaymentInCash;
  } else if (answer.text == "installments") {
    return simulateInstallments;
  } else {
    return scheduleMeeting;
  }
}

export async function simulateInstallments(chat, context) {
  await chat.sendMessage(
    "Dependendo da quantidade de parcelas, você pode ter um desconto na multa e juros de mora."
  );
  await chat.sendMessage(
    "De 2 a 12 parcelas, o desconto é de 30% nos juros e multas. De 13 a 24, o desconto é de 20%. Mas é possível parcelar em até 60 meses, desde que o valor mínimo da parcela seja R$ 65,00."
  );
  await chat.sendMessage(`Em quantas parcelas deseja simular?`);
  chat.setPlaceholder("Em quantas parcelas pretende pagar?");

  let answer = await chat.waitInput();
  let numberInstallments = parseInt(answer.text);

  while (
    numberInstallments == NaN ||
    !(numberInstallments >= 2 && numberInstallments <= 60)
  ) {
    await chat.sendMessage("Preciso de um número entre 2 e 60.");
    answer = await chat.waitInput();
    numberInstallments = parseInt(answer.text);
    context.numberInstallments = numberInstallments;
  }

  try {
    chat.setLoading(true);

    let response = await debtService.simulateInstallments(
      context.selectedDebt,
      context.type,
      numberInstallments
    );
    chat.sendMessage(false);

    let firstInstallments = response.data.parcelas[0];
    let secondInstallments = response.data.parcelas[1];
    await chat.sendMessage(
      `A primeira parcela ficará no valor de ${formatNumReais(
        firstInstallments.valor
      )}*`
    );
    await chat.sendMessage(
      `Da segunda parcela em diante o valor será de ${formatNumReais(
        secondInstallments.valor
      )}*`
    );
    await chat.sendMessage(
      `*Pode haver pequenas diferenças entre os valores das parcelas simulados e os valores confirmados.`
    );
    await chat.sendMessage("Deseja continuar com o parcelamento?");
    chat.showModalAnswers("Escolher opção", [
      {
        text: `Pagar à Vista (${formatNumReais(
          context.selectedDebt.totalAVista.total
        )})`,
        value: "incash",
      },
      { text: "Simular novo parcelamento", value: "installments" },
      { text: "Confirmar parcelamento", value: "confirm" },
      { text: "Agendar Atendimento Online", value: "schedule" },
    ]);

    let answer = await chat.waitInput();
    if (answer.text == "incash") {
      return handlePaymentInCash;
    } else if (answer.text == "installments") {
      return simulateInstallments;
    } else if (answer.text == "confirm") {
      return confirmInstallments;
    } else {
      return scheduleMeeting;
    }
  } catch (error) {
    console.log(error);
    await chat.sendMessage(
      `Não foi possível simular o parcelamento. Tente novamente mais tarde.`
    );
  } finally {
    chat.setLoading(false);
  }

  return sessionEnd;
}

export async function confirmInstallments(chat, context = {}) {
  if (!context.email) {
    await chat.sendMessage(
      "Precisamos apenas do seu email para enviar o parcelamento."
    );

    await chat.sendMessage("Por favor, digite seu email para continuar.");

    chat.setPlaceholder("Digite seu email");
    let answer = await chat.waitInput();

    while (answer.text.length <= 3) {
      chat.sendMessage(
        "Seu email está muito curto. Por favor, escreva seu email completo."
      );
      answer = await chat.waitInput();
    }

    chat.sendMessage("Muito Obrigado!");
  }

  await chat.sendMessage(
    "Confirma o acordo? Será lhe enviado um email com a primeira parcela de sua dívida."
  );
  chat.showModalAnswers([
    "Confirmar acordo",
    { value: "Sim" },
    { value: "Não" },
  ]);
  let answer = await chat.waitInput();

  if (answer.text == "Não") {
    return simulateInstallments;
  }

  chat.sendMessage("Aguarde um momento...");

  try {
    await debtService.confirmInstallments(
      context.selectedDebt,
      context.qtyInstallments,
      context.email
    );
    chat.sendMessage("Acordo feito com sucesso.");
    chat.sendMessage("A primeira parcela será enviado para seu email.");
    chat.sendMessage(
      "Obrigado por colaborar com a prefeitura de Recife. Até mais."
    );
  } catch (error) {
    console.log(error);
    chat.sendMessage(
      "Não foi possível confirmar o parcelamento. Tente novament mais tarde."
    );
  }

  return sessionEnd;
}

export async function scheduleMeeting(chat, context = {}) {
  await chat.sendMessage("Vamos marcar um horário então.");

  await chat.sendMessage("Preciso do seu email para continuar.");
  chat.setPlaceholder("Digite seu email");
  let answer = await chat.waitInput();

  context.email = answer.text;

  await chat.sendMessage("Agora me diga seu telefone.");
  chat.setPlaceholder("Digite seu telefone");
  answer = await chat.waitInput();

  context.phone = answer.text;

  chat.sendMessage("Muito Obrigado!");
  chat.sendMessage(
    "Aguarde um momento. Estou buscando os horários disponíveis..."
  );

  try {
    let response = await scheduleService.freeTime();
    let slots = response.data.map((slot) => moment(slot).add(3, "hours"));

    let options = slots.map((slot) => ({
      value: slot.toDate(),
      text: moment(slot).format("dddd, DD/MM [às] HH:mm"),
    }));

    chat.sendMessage(
      "Por favor, selecione uma data e horário para atendimento."
    );
    chat.showModalAnswers("Escolher uma data para o agendamento", options);
    answer = await chat.waitInput();
    let dateChoosed = answer.text;

    let ano = context.selectedDebt.exercicio || context.selectedDebt.exercicios;
    if (context.type == "cda") {
      var valor = formatNumReais(context.selectedDebt.totalSemDesconto.total);
    } else {
      var valor = 0.0;
      if (context.selectedDebt.parcelasVencidas) {
        valor += context.selectedDebt.parcelasVencidas.totalSemDesconto.total;
      }

      if (context.selectedDebt.parcelasFuturas) {
        valor += context.selectedDebt.parcelasFuturas.totalSemDesconto;
      }
      valor = formatNumReais(valor);
    }
    let text = `O Sr(a), ${context.name} gostaria de negociar suas dívidas de ${ano} que está no Imóvel com sequencial de ${context.sequencial} no valor ${valor}. Telefone: ${context.phone}; Email: ${context.email}`;

    await scheduleService.schedule(context.email, text, dateChoosed);
    await chat.sendMessage(
      "Agendado com sucesso.  Enviaremos as instruções para acessar a vídeo chamada no seu email."
    );
  } catch (error) {
    console.log(error);
    chat.sendMessage(
      "Não foi possível confirmar o parcelamento. Tente novamente mais tarde."
    );
  }

  return sessionEnd;
}

export async function sessionEnd(chat, context) {
  await chat.sendMessage(
    "Caso queira recomeçar, me mande uma nova mensagem. Este atendimento foi encerrado."
  );
  await chat.waitInput();
  return welcome;
}
