import React, { useState, useEffect } from "react";
import {
  GiftedChat,
  Send,
  InputToolbar,
  Bubble,
  Time,
  Composer
} from "react-native-gifted-chat";
import { v4 as uuidv4 } from "uuid";
import {
  View,
  Text,
  ActivityIndicator,
  TouchableHighlight,
  Modal,
  StyleSheet,
  ScrollView,
  Platform
} from "react-native";
import Helpers from "../../Theme/Helpers";
import Metrics from "../../Theme/Metrics";
import { MaterialIcons } from "@expo/vector-icons";

import LiaAvatar from "../../Assets/Images/avatar_lia.png";
import ChatHeader from "../../Components/Header";
// import Pdf from 'react-native-pdf'
import * as ACTION from "../../Actions";
import { USER, PLACEHOLDER } from "../../Util/constants";
import { sleep } from "../../Util/util";
import { Config } from "../../Config";
import { Colors } from "../../Theme";

export function ChatScreen({ navigation }) {
  const [messages, setMessages] = useState([]);
  const [inputEditable, setInputEditable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [placeholder, setPlaceholder] = useState(PLACEHOLDER.DEFAULT);
  const [toolbarOptionsVisibile, setToolbarOptionsVisibile] = useState(false);
  const [toolbarOptions, setToolbarOptions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [options, setOptions] = useState([]);
  const [modalTitle, setModalTitle] = useState("");

  let context = {};

  useEffect(() => {
    setLoading(false);
    setSubscribers([]);
    setModalVisible(false);
    setToolbarOptionsVisibile(false);
    setToolbarOptions([]);
    setPlaceholder(PLACEHOLDER.DEFAULT);
    setMessages([]);
    setOptions([]);
    setModalTitle("");

    handleFlow();
  }, []);

  const onSendOption = (option) => {
    // When user chooses a item from modal, the system can return the same message selected
    // or a value that represent that message.
    publish(getFomattedMessage(option.value || option.text));
    sendMessage(option.text || option.value, USER.CLIENT, false);
    setModalVisible(false);
    setToolbarOptionsVisibile(false);
  };

  const getFomattedMessage = (message, sender = USER.SYSTEM, ...props) => {
    let formattedMessages = {
      _id: uuidv4(),
      text: message,
      user: { _id: sender },
      createdAt: new Date(),
    };

    props.map((prop) => {
      formattedMessages = { ...formattedMessages, ...prop };
    });

    if (sender == USER.SYSTEM) {
      formattedMessages.user.avatar = LiaAvatar;
    }
    return formattedMessages;
  };

  const sendMessage = async (
    message,
    sender = USER.SYSTEM,
    broadcast = true,
    ...props
  ) => {
    // Gifted chat send us an array instead of a string.
    // So, we take the first message and extract the information from it and we create our message.
    message = Array.isArray(message) ? message[0].text : message;
    const formattedMessages = getFomattedMessage(message, sender, ...props);
    if (sender !== USER.CLIENT) {
      await sleep(Config.MESSAGE_DELAY_TIME);
    }
    // It expects a array.
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, [formattedMessages])
    );

    // Calls all subscriber listening to user input.
    if (sender == USER.CLIENT && broadcast) {
      publish(formattedMessages);
    }
  };

  const publish = (message) => {
    // Calls subscribers waiting for user to send a message;

    subscribers.map((fn) => {
      fn(message);
    });
    // Unregisters all subcribers
    setSubscribers([]);
  };

  const renderInputToolbar = (props) => {
    if (toolbarOptionsVisibile) {
      return (
        <View style={styles.selectOption}>
          <TouchableHighlight
            onPress={() => setModalVisible(true)}
            style={styles.selectButton}
          >
            <Text style={styles.selectButtonText}>{modalTitle}</Text>
          </TouchableHighlight>
        </View>
      );
    }
    return <InputToolbar {...props} textInputProps={{ editable: !loading }} />;
  };

  const SendButton = (props) => {
    if (loading) {
      return (
        <ActivityIndicator
          style={Metrics.smallHorizontalMargin}
          size={35}
          color="green"
        />
      );
    }
    return (
      <Send {...props} containerStyle={Helpers.center}>
        <MaterialIcons
          style={[Metrics.smallHorizontalMargin, styles.senderButton]}
          name="send"
          size={25}
        />
      </Send>
    );
  };

  const renderBubble = (props) => {
    if (props.currentMessage.type == "pdf") {
      const source = {
        uri: "data:application/pdf;base64," + props.currentMessage.text,
      };
      return (
        <View style={styles.container}>
          {/* <Pdf source={source} style={styles.pdf} /> */}
        </View>
      );
    }
    return (
      <Bubble
        {...props}
        textStyle={{ left: styles.systemMessageTextStyle }}
        wrapperStyle={{
          left: styles.systemMessageBackground,
          right: styles.userMessageBackground,
        }}
      />
    );
  };

  const renderTime = (props) => {
    return (
      <Time
        {...props}
        timeTextStyle={{ left: styles.systemMessageTextStyle }}
      />
    );
  };


  const renderComposer = (props) => (
    <Composer
      {...props}
      textInputProps={{
        ...props.textInputProps,
        // for enabling the Return key to send a message only on web
        blurOnSubmit: Platform.OS === "web",
        onSubmitEditing:
          Platform.OS === "web"
            ? () => {
                if (props.text && props.onSend) {
                  props.onSend({ text: props.text.trim() }, true);
                }
              }
            : undefined,
      }}
    />
  );

  const showModalAnswers = (modalTitle_, options_) => {
    setModalTitle(modalTitle_);
    setOptions(options_);
    showToolBarOption(true);
  };

  function showToolBarOption() {
    setToolbarOptionsVisibile(true);
  }

  function waitInput() {
    return new Promise((resolve, reject) => {
      setSubscribers([...subscribers, resolve]);
    });
  }

  const ModalAnswer = ({ title = "", options = [], onPress = () => {} }) => {
    return (
      <Modal
        style={styles.modalContainer}
        transparent={true}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={Helpers.fillCenter}>
          <View style={styles.modalWrapper}>
            <TouchableHighlight
              onPress={() => setModalVisible(false)}
              style={styles.closeModalButton}
            >
              <MaterialIcons style={styles.closeModalButtonText} name="close" />
            </TouchableHighlight>
            <ScrollView showsVerticalScrollIndicator={false}>
              {options.map((option, index) => (
                <TouchableHighlight
                  style={styles.optionsWrapper}
                  onPress={() => onPress(option)}
                  key={index}
                >
                  <Text style={styles.optionText} key={index}>
                    {option.text || option.value}
                  </Text>
                </TouchableHighlight>
              ))}
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  };

  const chat = {
    sendMessage,
    setPlaceholder,
    setLoading,
    showModalAnswers,
    waitInput,
    showToolBarOption,
    setInputEditable,
  };

  const handleFlow = async () => {
    let nextHandler = await ACTION.welcome(chat, context);

    while (nextHandler !== null) {
      nextHandler = await nextHandler(chat, context);
    }
  };

  return (
    <View style={Helpers.fill}>
      <ChatHeader onPressBackButton={() => navigation.goBack()} />
      <GiftedChat
        locale="pt-br"
        placeholder={placeholder}
        renderSend={SendButton}
        renderBubble={renderBubble}
        renderTime={renderTime}
        renderComposer={renderComposer}
        messages={messages}
        onSend={(message) => sendMessage(message, USER.CLIENT)}
        alwaysShowSend={true}
        renderInputToolbar={renderInputToolbar}
        user={{
          _id: 1,
        }}
      />
      {modalVisible && (
        <ModalAnswer
          visible={modalVisible}
          title={modalTitle}
          options={options}
          onPress={onSendOption}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  messageInput: {
    flex: 8,
  },
  senderButton: {
    color: Colors.yellow,
  },

  systemMessageBackground: {
    backgroundColor: Colors.lightBlue,
  },

  systemMessageTextStyle: {
    color: Colors.white,
  },

  userMessageBackground: {
    backgroundColor: Colors.yellow,
  },

  userMessageTextStyle: {
    color: Colors.darkBlue,
  },

  modalContainer: {
    zIndex: 2,
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },

  modalWrapper: {
    position: "relative",
    backgroundColor: Colors.darkBlue,
    padding: 20,
    borderRadius: 30,
    marginTop: 50,
    marginBottom: 20,
    maxHeight: "80%",
    maxWidth: "80%",
  },

  modalTitle: {
    textAlign: "justify",
    fontSize: 16,
    fontWeight: "bold",
    color: Colors.white,
  },

  optionsWrapper: {
    alignItems: "center",
    backgroundColor: Colors.yellow,
    borderRadius: 10,
    marginTop: 10,
    paddingVertical: 10,
  },

  optionText: {
    fontWeight: "bold",
    fontSize: 15,
    color: Colors.darkBlue,
  },

  selectOption: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  selectButton: {
    backgroundColor: Colors.darkBlue,
    paddingHorizontal: 30,
    marginRight: 10,
    paddingVertical: 5,
    borderRadius: 10,
  },
  selectButtonText: {
    color: Colors.white,
    fontWeight: "bold",
  },

  closeModalButton: {
    backgroundColor: Colors.darkBlue,
    borderRadius: 10,
    marginVertical: 10,
    alignSelf: "center",

  },
  closeModalButtonText: {
    padding: 5,
    fontSize: 20,
    color: Colors.white,
    fontWeight: "bold",
  },
});

export default ChatScreen;
